/* eslint-disable no-magic-numbers */
import { getValueFromMap } from '@/helpers/getValueFromMap';

export const DELIVERY_TYPE_MORNING = 'morning';
export const DELIVERY_TYPE_STANDARD = 'standard';
export const DELIVERY_TYPE_EVENING = 'evening';
export const DELIVERY_TYPE_PICKUP = 'pickup';
export const DELIVERY_TYPE_PICKUP_EXPRESS_DELIVERY = 'pickup express delivery';
export const DELIVERY_TYPE_SAME_DAY_DELIVERY = 'same day delivery';
export const DELIVERY_TYPE_EXPRESS_DELIVERY = 'express delivery';

const deliveryTypeMapping = [
  {
    id: 1,
    name: DELIVERY_TYPE_MORNING,
  },
  {
    id: 2,
    name: DELIVERY_TYPE_STANDARD,
  },
  {
    id: 3,
    name: DELIVERY_TYPE_EVENING,
  },
  {
    id: 4,
    name: DELIVERY_TYPE_PICKUP,
  },
  {
    id: 5,
    name: DELIVERY_TYPE_PICKUP_EXPRESS_DELIVERY,
  },
  {
    id: 6,
    name: DELIVERY_TYPE_SAME_DAY_DELIVERY,
  },
  {
    id: 7,
    name: DELIVERY_TYPE_EXPRESS_DELIVERY,
  },
];

/**
 * Get delivery type from given id or name.
 *
 * @param {String|Number} packageType
 * @returns {Object}
 */
export function getDeliveryType(packageType) {
  return getValueFromMap(packageType, deliveryTypeMapping);
}
