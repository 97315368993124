import { ADDRESS_FINDER_GROUP } from '@/data/forms/addressFinder';

/**
 * Update recipient object, setting postal code from address finder fields.
 *
 * @param {Object} recipient
 * @returns {Object}
 */
export function setPostalCodeFromAddressFinderData(recipient = {}) {
  const addressFinder = recipient?.[ADDRESS_FINDER_GROUP];

  if (!addressFinder) {
    return recipient;
  }

  recipient.postal_code = addressFinder.postal_code ?? null;
  recipient.number = addressFinder.number ?? null;

  return recipient;
}

/**
 * Filter out properties from the list and remove the empty values.
 *
 * @param {Array} list
 * @param {Object} recipient
 * @returns {Object}
 */
export function removeKeysAndValues(list = [], recipient = {}) {
  Object.keys(recipient).forEach((key) => {
    const isIntheList = list.includes(key);
    const isNull = recipient[key] === null;
    const isUndefined = typeof recipient[key] === 'undefined';

    if (isIntheList || isNull || isUndefined) {
      delete recipient[key];
    }
  });

  return recipient;
}
