import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';

dayjs.extend(utc);

/**
 * Convert to the desired date notation.
 * Example: 2021-01-01 12:00:00.
 *
 * @param {String} dateString
 * @returns {String}
 * */
export function formatDeliveryDate(dateString) {
  return dayjs(dateString).utc().format('YYYY-MM-DD HH:mm:ss');
}
