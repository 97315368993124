<template>
  <Card
    title="social_shipping.finalize_title"
    class="text-center">
    <p v-t="'social_shipping.finalize_description'" />
    <StatusButton
      v-test="{ e2e: 'finalize_submit', id: 'finalize_submit' }"
      :loading="loading"
      :success="success"
      @click="onSubmit">
      {{ $t('form.submit') }}
    </StatusButton>

    <SmoothReflow>
      <Alert
        v-if="formError"
        class="d-inline-block mt-3"
        variant="danger"
        :text="formError" />
    </SmoothReflow>
  </Card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { removeKeysAndValues, setPostalCodeFromAddressFinderData } from '@/store/modules/social-shipping/helpers';
import { ApiError } from '@/services/ApiError';
import Card from '@/components/Card';
import StatusButton from '@/components/StatusButton';
import { formatDeliveryDate } from '@/helpers/deliveryDateConfig';
import { getDeliveryType } from '@/helpers/deliveryTypeConfig';
import { socialShippingFormBus } from '@/views/SocialShipping/socialShippingFormBus';

export default {
  name: 'SocialShippingFinalize',
  components: {
    StatusButton,
    Alert: () => import('@/components/Alert'),
    Card,
  },

  props: {
    hasDeliveryOptions: {
      type: Boolean,
    },
  },

  data() {
    return {
      formError: null,
      loading: false,
      success: null,
    };
  },

  computed: {
    ...mapState('socialShipping', ['socialShippingLink', 'deliveryOptions']),
    ...mapGetters('socialShipping', ['recipient']),

    /**
     * Data to use with the put request to update the social shipping link.
     *
     * @returns {Object}
     */
    newShipmentOptions() {
      /**
       * Format the recipient to be ready to be sent to the API.
       * Some data will be updated or removed.
       *
       * @param {Object} recipient
       * @returns {Object} NewRecipient.
       */
      function formatRecipient(recipient) {
        const newRecipient = setPostalCodeFromAddressFinderData(recipient);

        const keysToBeRemoved = ['addressFinder', 'isValid'];
        return removeKeysAndValues(keysToBeRemoved, newRecipient);
      }

      const deliveryDate = this.deliveryOptions?.date
        ? formatDeliveryDate(this.deliveryOptions?.date)
        : null;

      const deliveryType = this.deliveryOptions?.deliveryType
        ? getDeliveryType(this.deliveryOptions?.deliveryType).id
        : null;

      const pickup = this.deliveryOptions?.isPickup
        ? this.deliveryOptions?.pickupLocation
        : null;

      return {
        options: {
          delivery_date: deliveryDate,
          delivery_type: deliveryType,
        },

        pickup: pickup,
        recipient: formatRecipient(this.recipient),
      };
    },
  },

  mounted() {
    // Remove the invalid form alert when the user is editing fields.
    socialShippingFormBus.$on('update:recipient', () => {
      if (this.formError) {
        this.formError = null;
      }
    });

    // Trigger submit when the submit event is called on the formBus.
    socialShippingFormBus.$on('submit', this.onSubmit);
  },

  methods: {
    /**
     * Submit the form. If the address is invalid or the delivery options are not filled in, show an error and don't
     *  proceed with sending a request.
     */
    async onSubmit() {
      if (this.loading || this.success) {
        return;
      }

      this.formError = false;

      // If the form is still validating, return.
      if (socialShippingFormBus.$v.$pending) {
        return;
      }

      // If form is not valid, show an error.
      if (!socialShippingFormBus.isReady()) {
        this.formError = 'form.error_input_invalid';
        return;
      }

      // If no delivery option is picked, show an error.
      if (this.hasDeliveryOptions && !this.deliveryOptions) {
        this.formError = 'form.error_delivery_options';
        return;
      }

      await this.updateSocialShippingLink();
    },

    /**
     * Sends gathered data to the API, so it can make a shipment.
     *
     * @returns {Promise<void>}
     */
    async updateSocialShippingLink() {
      this.success = null;
      this.loading = true;

      const response = await this.$store.dispatch('socialShipping/putSocialShippingLink', this.newShipmentOptions);
      this.loading = false;

      if (response instanceof ApiError) {
        this.success = false;
        return;
      }

      this.success = true;
    },
  },
};
</script>
